import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const SectionOne = styled.section`
  display: flex;
  justify-content: space-between;

  width: 100%;
  max-width: 912px;

  height: 100%;

  margin-top: 100px;

  @media (max-width: 1440px) {
    margin: 0 29px;
    margin-top: 100px;
  }

  @media (max-width: 862px) {
    display: none;
  }
`;

export const SectionOneLeft = styled.div`
  width: 100%;
  max-width: 340px;

  height: fit-content;

  h2 {
    font-size: 48px;
    font-weight: 700;

    line-height: 48px;
    height: 115px;

    color: ${colors.blue400};
  }

  p {
    font-size: 16px;
    line-height: 20px;

    margin-top: 24px;
  }
`;

export const SectionOneRight = styled.div`
  img {
    border-radius: 16px;
  }
`;
