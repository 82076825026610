import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const SectionOneHidden = styled.section`
  justify-content: space-between;
  max-width: 912px;

  height: fit-content;

  margin-top: 25px;

  display: none;

  h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 48px;

    width: 100%;
    max-width: 340px;

    height: 115px;

    color: ${colors.blue400};
  }

  p {
    font-size: 16px;
    line-height: 20px;

    max-width: 320px;

    width: 100%;

    margin-top: 21px;
  }

  p + p {
    margin-top: 30px;
  }

  div {
    width: fit-content;
  }

  img {
    border-radius: 16px;
  }

  @media (max-width: 1440px) {
    margin: 0px auto;
    margin-top: 25px;
  }

  @media (max-width: 862px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 500px) {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 48px;

    width: 100%;
    max-width: 396px;
  }
`;
