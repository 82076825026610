import styled from 'styled-components';
import { colors } from '@/styles/GlobalStyle';

export const SectionThree = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin: 100px 0;

  text-align: center;

  @media (max-width: 1440px) {
    margin: 80px 29px;
  }

  @media (max-width: 990px) {
    flex-direction: column-reverse;
    text-align: start;

    margin-top: 32px;
    margin-bottom: 32px;

    min-height: 504px;
  }

  @media (max-width: 904px) {
    margin: 32px 29px;

    a {
      margin-top: 32px;
    }
  }
  @media (max-width: 500px) {
    width: 100%;
    max-width: 396px;

    margin: 32px 16px;
  }
`;

export const SectionThreeTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 571px;

  height: fit-content;

  h2 {
    color: ${colors.blue400};

    font-size: 48px;
    font-weight: 700;
    line-height: 72px;
  }

  a {
    justify-content: center;
    align-items: center;

    background-color: ${colors.blue400};
    color: white;

    width: 263px;

    height: 40px;

    border: none;
    border-radius: 8.4px;
    outline: none;

    font-size: 16px;
    font-weight: 500;

    display: none;
  }

  p {
    line-height: 20px;

    margin-top: 24px;
  }

  @media (max-width: 990px) {
    margin-bottom: 32px;

    p + p {
      margin-top: 0;
    }
    a {
      align-self: center;
      display: flex;
    }
  }

  @media (max-width: 500px) {
    margin-bottom: 0;

    height: fit-content;
    h2 {
      align-self: flex-start;
    }

    p {
      margin: 0;
    }
    p + p {
      margin-top: 25px;
    }
  }
`;

export const SectionThreeMiddle = styled.div`
  height: fit-content;

  margin-top: 38px;

  div {
    height: fit-content;

    img {
      height: 312px;
      border-radius: 12px;
    }
  }

  @media (max-width: 990px) {
    margin-top: 0;
  }
`;

export const SectionThreeBottom = styled.div`
  a {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${colors.blue400};
    color: white;

    width: 263px;

    height: 40px;

    border: none;
    outline: none;
    border-radius: 8.4px;

    font-size: 16px;
    font-weight: 500;

    margin-top: 52px;

    &:hover {
      color: white;
      background-color: #052373;
      transition: 0.2s;
    }
  }

  @media (max-width: 990px) {
    a {
      display: none;
    }
  }
`;
