import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { HeroSection } from './styles';

const HeroSectionComponent = (): JSX.Element => {
  return (
    <HeroSection>
      <StaticImage
        src="../../../../images/paginas/nossa-estrutura/top-main-caminhoes.png"
        alt="Imagem de caminhões"
        quality={100}
        placeholder="blurred"
      />
      <h1>Nossa estrutura</h1>
    </HeroSection>
  );
};

export default HeroSectionComponent;
