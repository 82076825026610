import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { SectionTwo, SectionTwoLeft, SectionTwoRight } from './styles';

const SectionTwoComponent = (): JSX.Element => {
  return (
    <SectionTwo>
      <SectionTwoLeft>
        <StaticImage
          src="../../../../images/paginas/nossa-estrutura/section-two-estrutura.png"
          alt="Imagem da estrutura do local"
          quality={100}
        />
      </SectionTwoLeft>

      <SectionTwoRight>
        <h2>Estrutura</h2>

        <p>
          Nossos terminais estão localizados nos principais centros logísticos
          de SP, RJ e ES projetados para dar velocidade ao fluxo de cargas.
        </p>

        <p>
          Contamos com mais de 300 veículos urbanos e rodoviários, leves e
          pesados, proporcionando coletas, transferências e entregas na medida
          para sua necessidade.
        </p>
      </SectionTwoRight>
    </SectionTwo>
  );
};

export default SectionTwoComponent;
