import React from 'react';
import Layout from '@/components/Layout';
import BreadCrumbs from '@/components/BreadCrumbs';
import SEO from '@/components/SEO';

import { graphql, useStaticQuery } from 'gatsby';

import {
  PageWrapper,
  PageContainer,
  SectionWrapper,
  BreadCrumbsWrapper,
} from '../styles/pages/nossa-estrutura';

import HeroSection from '@/components/Pages/NossaEstrutura/HeroSection';
import SectionOneHidden from '@/components/Pages/NossaEstrutura/SectionOneHidden';
import SectionOne from '@/components/Pages/NossaEstrutura/SectionOne';
import SectionTwo from '@/components/Pages/NossaEstrutura/SectionTwo';
import SectionThree from '@/components/Pages/NossaEstrutura/SectionThree';
import { CMS_URL } from '@/config';

const pageBreadCrumbs = [
  {
    name: 'Página Inicial',
    relativeURL: '/',
  },
  { name: 'A Generoso' },

  { name: 'Nossa Estrutura' },
];

const NossaEstruturaPage = (): JSX.Element => {
  const query = graphql`
    query {
      directus {
        site_pages(filter: { slug: { _contains: "nossa-estrutura" } }) {
          seo {
            title
            description
            keywords
            image {
              id
            }
          }
        }
      }
    }
  `;

  const { directus } = useStaticQuery(query);

  const SEOData = directus.site_pages[0].seo;

  return (
    <>
      <SEO
        pageTitle="Estrutura"
        title={SEOData.title}
        description={SEOData.description}
        image={`${CMS_URL}/assets/${SEOData.image}`}
        keywords={SEOData.keywords}
      />
      <Layout>
        <PageWrapper>
          <PageContainer>
            <SectionWrapper>
              <HeroSection />
            </SectionWrapper>
            <BreadCrumbsWrapper>
              <BreadCrumbs pageBreadCrumbs={pageBreadCrumbs} />
            </BreadCrumbsWrapper>
            <SectionWrapper>
              <SectionOneHidden />
            </SectionWrapper>
            <SectionWrapper>
              <SectionOne />
            </SectionWrapper>
            <SectionWrapper>
              <SectionTwo />
            </SectionWrapper>
            <SectionWrapper>
              <SectionThree />
            </SectionWrapper>
          </PageContainer>
        </PageWrapper>
      </Layout>
    </>
  );
};

export default NossaEstruturaPage;
