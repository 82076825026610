import styled from 'styled-components';
import { colors } from '@/styles/GlobalStyle';

export const SectionTwo = styled.section`
  display: flex;

  width: 100%;
  max-width: 912px;

  margin-top: 65px;

  height: fit-content;

  @media (max-width: 1440px) {
    margin: 0 29px;
    margin-top: 65px;
  }

  @media (max-width: 904px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
    margin-top: 32px;

    width: fit-content;
  }

  @media (max-width: 500px) {
    width: 100%;
    max-width: 396px;

    margin: 32px 16px;
  }
`;

export const SectionTwoLeft = styled.div`
  width: fit-content;
  border-radius: 16px;

  overflow: hidden;

  background-color: transparent;
`;

export const SectionTwoRight = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 340px;

  margin-left: 83px;

  height: fit-content;
  min-height: 294px;

  h2 {
    font-size: 48px;
    font-weight: 700;

    line-height: 67px;

    color: ${colors.blue400};
  }

  p {
    font-size: 16px;
    line-height: 20px;

    width: 100%;
    max-width: 327px;

    margin-top: 24px;
  }

  @media (max-width: 1440px) {
    /* margin: 0; */
  }
  @media (max-width: 904px) {
    justify-content: flex-start;

    min-height: unset;
    width: fit-content;

    margin-left: 0px;

    h2 {
      margin-top: 9px;
    }

    p {
      margin-top: 10px;
    }

    p + p {
      margin-top: 30px;
    }
  }

  @media (max-width: 500px) {
    width: 100%;
    max-width: 396px;

    p {
      width: 100%;
      max-width: 396px;
    }
  }

  @media (max-width: 320px) {
    h2 {
      font-size: 40px;
    }
  }
`;
