import styled from 'styled-components';
import { colors } from '@/styles/GlobalStyle';

export const HeroSection = styled.div`
  display: flex;
  align-items: center;
  /* background-color: ${colors.blue400}; */

  .gatsby-image-wrapper {
    background-color: ${colors.blue400};
    /* opacity: 0.84; */

    img {
      mix-blend-mode: soft-light;
    }
  }

  h1 {
    font-size: 72px;
    font-weight: 700;

    color: white;

    position: absolute;

    margin-left: 233px;

    width: 100%;
    max-width: 351px;

    line-height: 72px;
  }
  @media (max-width: 1200px) {
    .gatsby-image-wrapper {
      img {
        width: 100%;
        height: 490px;
      }
    }
  }

  @media (max-width: 860px) {
    justify-content: center;

    h1 {
      margin: 0;

      text-align: center;
    }
  }

  @media (max-width: 690px) {
    margin: 0px 0px;

    .gatsby-image-wrapper {
      img {
        width: 100%;
        height: 400px;
      }
    }
  }

  @media (max-width: 500px) {
    h1 {
      font-size: 57px;
      line-height: 57px;
    }
  }
`;
