/* eslint-disable */
/* prettier-ignore */

import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import {
  SectionThree,
  SectionThreeBottom,
  SectionThreeMiddle,
  SectionThreeTop,
} from './styles';
import { Link } from 'gatsby';

const SectionThreeComponent = (): JSX.Element => {
  return (
    <SectionThree>
      <SectionThreeTop>
        <h2>Nosso time</h2>

        <p>
          Nosso time conta com mais de 700 colaboradores todos treinados e
          capacitados, cuidamos da sua encomenda e fazemos o máximo para que a
          entrega supere todas as suas expectativas.
        </p>

        <p>
          Todos os volumes são etiquetados permitindo rastreabilidade e
          reduzindo não conformidades.
        </p>
        <a href="https://cliente.generoso.com.br/unidades" target='_blank' rel="noreferrer">Conheça nossas unidades</a>
      </SectionThreeTop>

      <SectionThreeMiddle>
        <StaticImage
          src="../../../../images/paginas/nossa-estrutura/section-three-reuniao.png"
          alt="Pessoas em uma reuniao"
          quality={100}
        />
      </SectionThreeMiddle>

      <SectionThreeBottom>
        <a href="https://cliente.generoso.com.br/unidades" target='_blank' rel="noreferrer">Conheça nossas unidades</a>
      </SectionThreeBottom>
    </SectionThree>
  );
};

export default SectionThreeComponent;
