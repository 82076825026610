import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { SectionOne, SectionOneLeft, SectionOneRight } from './styles';

const SectionOneComponent = (): JSX.Element => {
  return (
    <SectionOne>
      <SectionOneLeft>
        <h2>Em constante crescimento</h2>
        <p>
          Estamos nos fortalecendo e ampliando nossa atuação no cenário
          Nacional, em parceria com os 3 principais players do segmento de
          transporte regional.
        </p>
        <p>
          Aumentando nosso alcance, chegamos em mais cidades, e ficamos mais
          perto do seu negócio.
        </p>
      </SectionOneLeft>

      <SectionOneRight>
        <StaticImage
          src="../../../../images/paginas/nossa-estrutura/section-one-caminhoes.png"
          alt="Imagem de caminhões"
          quality={100}
        />
      </SectionOneRight>
    </SectionOne>
  );
};

export default SectionOneComponent;
